<template>
    <v-container class="toggle animated" fluid>
      <v-row dense>
        <v-col cols="12" md="4">
          <v-text-field
              v-model="search"
              variant="outlined"
              prepend-inner-icon="mdi-magnify"
              label="Search"
              density="comfortable"
              solo
              hide-details
              class="rounded-lg"
              color="white"
              clearable
              style="font-family: Saira Condensed, sans-serif;"></v-text-field>
        </v-col>
        <v-col class="text-right" cols="12" md="8">
            <!-- <v-btn
            @click="showAddDialog()"
            prepend-icon="mdi-plus"
            class="mt-2 rounded-lg"
            color="primary"
            height="40px"
            elevation="2" style="font-family: Saira Condensed, sans-serif;">
                Add Product
            </v-btn> -->
        </v-col>
        <v-col cols="12">
          <v-card class="dt-container mt-2">    
              <v-data-table
                  :mobile="isMobileOrTablet()"
                  :headers="headers"
                  :items="data"
                  :search="search"
                  :loading="loading"
                  class="text-subtitle-1"
                  style="font-family: Saira Condensed, sans-serif;">
              <template v-slot:loading>
                <v-progress-linear indeterminate color="primary" height="5" class="mt-1 rounded-lg"></v-progress-linear>
                <!-- <v-skeleton-loader type="table-row@10"></v-skeleton-loader> -->
              </template>
              <template v-slot:item.status="{ item }">
                    <v-chip v-if="item.status == 'active'" color="green">
                        <span>Active</span>
                    </v-chip>
                    <v-chip v-if="item.status == 'inactive'" color="red">
                        <span>Inactive</span>
                    </v-chip>
                </template>
                <template v-slot:item.totalWagers="{ item }">
                    <v-chip v-for="i in item.totalWagers" :key="i">
                        <span class="font-weight-bold" style="text-transform: capitalize;">{{ i.leaderboardType }}: </span>
                        <span class="ml-1">${{ i.totalWager }}</span>
                    </v-chip>
                </template>
                <template v-slot:[`item.action`]="{ item }">
                <v-menu offset-y>
                    <template v-slot:activator="{ props }">
                    <v-btn v-bind="props" size="small">
                        Total Wager
                    </v-btn>
                    </template>
                    <v-list>
                        <v-list-item @click="showTotalWagerDialog(item, 'metaspins')">
                            <v-list-item-title class="text-subtitle-1" style="font-family: Saira Condensed, sans-serif;">Metaspins</v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
                <v-menu offset-y>
                    <template v-slot:activator="{ props }">
                    <v-btn v-bind="props" size="small" class="ml-2">
                        Milestones
                    </v-btn>
                    </template>
                    <v-list>
                        <v-list-item v-for="m in item.milestones" @click="showMilestoneDialog(item,m)">
                            <v-list-item-title v-if="m.leaderboardType == 'metaspins'" class="text-subtitle-1" style="font-family: Saira Condensed, sans-serif;">Metaspins</v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
                </template>
              </v-data-table>
          </v-card>
        </v-col>
      </v-row>
  </v-container>

    <v-snackbar
        v-model="snackBar"
        :timeout="2500"
        color="secondary"
        location="top">
        <v-icon>mdi-alert-circle</v-icon>
        <span class="ml-2" style="font-family: Saira Condensed, sans-serif;">{{ snackText }}</span>
    </v-snackbar>

    <v-dialog v-model="totalWagerDialog" width="450" persistent style="font-family: Saira Condensed, sans-serif;">
            <v-card color="#ffffff" rounded="lg" class="pa-2">
                <v-card-title class="font-weight-bold">
                    Total Wager
                </v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col cols="12" md="6">
                            <div class="font-weight-bold">Username:</div>
                            <div>{{ userName }}</div>
                        </v-col>
                        <v-col cols="12" md="6">
                            <div class="font-weight-bold">Leaderboard Type:</div>
                            <div style="text-transform: capitalize;">{{ leaderboardType }}</div>
                        </v-col>
                        <v-col cols="12">
                            <v-text-field
                            v-model="totalWager"
                            :rules="[formRules.required]"
                            label="Total Wager"
                            type="number"
                            variant="outlined"
                            density="comfortable"
                            color="primary"
                            style="font-family: Saira Condensed, sans-serif;"
                        ></v-text-field>
                        </v-col>
                    </v-row>
                </v-card-text>
                <template v-slot:actions>
                <v-spacer></v-spacer>

                <v-btn @click="totalWagerDialog = false" variant="outlined">
                    Cancel
                </v-btn>
                <v-btn :loading="loadingTotalWager" @click="updateTotalWager()" color="primary" variant="elevated">
                    Confirm
                </v-btn>
                </template>
            </v-card>
        </v-dialog>

  <v-dialog v-model="deleteDialog" width="450" persistent>
        <v-card color="primary" rounded="lg" class="pa-2" style="font-family: Saira Condensed, sans-serif;">
            <v-card-text class="font-weight-bold">
                Are you sure you want to delete this product ?
                <br><br>{{ itemToDelete.title }}
            </v-card-text>
            <template v-slot:actions>
            <v-spacer></v-spacer>

            <v-btn @click="deleteDialog = false" variant="outlined">
                Cancel
            </v-btn>
            <v-btn @click="deleteItem()" color="red" variant="elevated">
                Confirm
            </v-btn>
            </template>
        </v-card>
    </v-dialog>

  <MilestoneDialog ref="MilestoneDialog" @refresh="init()"></MilestoneDialog>
</template>

<script>
import axios from 'axios';
import { defineComponent } from 'vue';
import MilestoneDialog from '../Dialogs/MilestoneDialog.vue';


export default defineComponent({
    components: {
       MilestoneDialog
    },
    data(){
        return {
            data: [],
            deleteDialog: false,
            totalWagerDialog: false,
            itemToDelete: null,
            search: null,
            loading: false,
            headers: [
                { title: 'Discord Username', align: 'start', sortable: true, key: 'username' },
                { title: 'Email', align: 'start', sortable: true, key: 'email' },
                { title: 'Total Wagered', align: 'start', sortable: true, key: 'totalWagers' },
                { title: 'Actions', align: 'end', sortable: false, key: 'action' },
            ],
            snackBar: false,
            snackText: null,

            userId: null,
            userName: null,
            totalWager: null,
            leaderboardType: null,

            loadingTotalWager: false
        }
    },
    methods: {
        init(){
            const token = localStorage.getItem('ballyboy-admin-accesstoken')
            if(token){
                this.loading = true
                axios.get(process.env.VUE_APP_SERVER_URL + '/api/admin/milestones/users',
                { headers: 
                    { 
                        'Authorization': `Bearer ${token}`,
                        'x-admin-key': process.env.VUE_APP_X_ADMIN_KEY,
                    }
                }
                ).then((res)=>{
                    if(res){
                        this.data = res.data.users
                        this.loading = false
                    }
                }).catch((error) => {
                    if (error.response) {
                        if (error.response.status === 401) {
                                localStorage.removeItem("vuex");
                                localStorage.removeItem("ballyboy-admin-accesstoken");
                                window.location.reload();
                        } else {
                            console.log(`Error: ${error.response.status}`);
                        }
                    } 
                    else if (error.request) {
                        console.log('No response received', error.request);
                    } else {
                        console.log('Error', error.message);
                    }
                });
            }
            else{
                localStorage.removeItem("vuex");
                localStorage.removeItem("ballyboy-admin-accesstoken");
                window.location.reload();
            }
        },
        showTotalWagerDialog(data, type){
            if(type == 'metaspins'){
                const metaspinsWagers = data.totalWagers.filter(wager => wager.leaderboardType === "metaspins");
                this.userId = data._id
                this.userName = data.username
                this.leaderboardType = 'metaspins'
                this.totalWager = metaspinsWagers.length != 0 ? metaspinsWagers[0]?.totalWager : 0
                this.totalWagerDialog = true
            }
        },
        showDeleteDialog(item){
            this.itemToDelete = item
            this.deleteDialog = true
        },
        updateTotalWager(){
            this.loadingTotalWager = true
            let formData = {
                userId: this.userId,
                leaderboardType: this.leaderboardType,
                totalWager: this.totalWager
            }
            const token = localStorage.getItem('ballyboy-admin-accesstoken')
            axios.post(process.env.VUE_APP_SERVER_URL + '/api/admin/milestones/totalWager/update', formData,
            { headers:
                { 
                    'Authorization': `Bearer ${token}`,
                    'x-admin-key': process.env.VUE_APP_X_ADMIN_KEY,
                }
            }
            ).then((res)=>{
                if(res.data){
                    this.init()
                    this.loadingTotalWager = false
                    this.totalWagerDialog = false
                }
            }).catch((error) => {
                if (error.response) {
                    if (error.response.status === 401) {
                            localStorage.removeItem("vuex");
                            localStorage.removeItem("ballyboy-admin-accesstoken");
                            window.location.reload();
                    } else {
                        console.log(`Error: ${error.response.status}`);
                    }
                } 
                else if (error.request) {
                    console.log('No response received', error.request);
                } else {
                    console.log('Error', error.message);
                }
            });
        },
        deleteItem(){
            const token = localStorage.getItem('ballyboy-admin-accesstoken')
            axios.delete(process.env.VUE_APP_SERVER_URL + '/api/admin/store/product/delete?id=' + this.itemToDelete._id,
            {
                headers: 
                { 
                    'Authorization': `Bearer ${token}`,
                    'x-admin-key': process.env.VUE_APP_X_ADMIN_KEY,
                },
            }
            ).then((res)=>{
                if(res.data){
                    this.snackBar = true
                    this.snackText = 'Product Succesfully Deleted'
                    this.init()
                    this.deleteDialog = false
                }
            }).catch((error) => {
                if (error.response) {
                    if (error.response.status === 401) {
                            localStorage.removeItem("vuex");
                            localStorage.removeItem("ballyboy-admin-accesstoken");
                            window.location.reload();
                    } else {
                        console.log(`Error: ${error.response.status}`);
                    }
                } 
                else if (error.request) {
                    console.log('No response received', error.request);
                } else {
                    console.log('Error', error.message);
                }
            });
        },
        // showAddDialog(){
        //     this.$refs.MilestoneDialog.initAdd()
        // },
        // showEditDialog(data){
        //     this.$refs.MilestoneDialog.initUpdate(data)
        // },
        showMilestoneDialog(data, milestones){
            this.$refs.MilestoneDialog.init(data, milestones)
        }
    },
    mounted(){
        this.init()
    }
}) 
</script>

<style scoped>

</style>