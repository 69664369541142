<template>
    <v-dialog v-model="dialog" width="800" persistent>
        <v-card class="rounded-lg" style="display: flex; flex-direction: column; height: 100%;">
            <v-card-title style="background-color: #373f49 !important; display: flex; justify-content: space-between; align-items: center; color: white;">
                <div>
                    <span class="font-weight-bold text-h6" style="font-family: Saira Condensed, sans-serif;">{{ title }}</span>
                    <div class="text-caption" style="font-family: Saira Condensed, sans-serif;">{{ sub_title }}</div>
                </div>
                <v-btn :disabled="loading" flat icon color="primary" @click="closeDialog()">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text style="overflow-y: auto; max-height: calc(100vh - 200px);">
                <v-form ref="form" class="mt-4">
                    <v-row dense>
                        <!-- <v-col v-if="action == 'edit'" cols="12">
                            <v-switch
                                v-model="status"
                                label="Status"
                                color="green"
                                false-value="inactive"
                                true-value="active"
                                inset
                                style="font-family: Saira Condensed, sans-serif;"
                            ></v-switch>
                        </v-col> -->
                        <v-col cols="12" md="6">
                            <v-text-field
                                v-model="username"
                                :rules="[formRules.required, formRules.noSpacesOnly]"
                                label="Username"
                                type="text"
                                variant="outlined"
                                density="comfortable"
                                color="primary"
                                style="font-family: Saira Condensed, sans-serif;"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-text-field
                                v-model="betId"
                                :rules="[formRules.required, formRules.noSpacesOnly]"
                                label="Bet ID"
                                type="text"
                                variant="outlined"
                                density="comfortable"
                                color="primary"
                                style="font-family: Saira Condensed, sans-serif;"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-text-field
                                v-model="multi"
                                :rules="[formRules.required, formRules.maxLength, formRules.minValue]"
                                label="Multi"
                                type="number"
                                variant="outlined"
                                density="comfortable"
                                color="primary"
                                style="font-family: Saira Condensed, sans-serif;"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-text-field
                                v-model="discordUsername"
                                :rules="[formRules.required, formRules.noSpacesOnly]"
                                label="Discord Username"
                                hint="This way you can display the user profile image"
                                type="text"
                                variant="outlined"
                                density="comfortable"
                                color="primary"
                                style="font-family: Saira Condensed, sans-serif;"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions class="pa-3" style="margin-top: auto;">
                <v-spacer></v-spacer>
                <v-btn class="mr-1 text-subtitle-1" :disabled="loading" text="Cancel" variant="outlined" elevation="1" @click="closeDialog()" style="font-family: Saira Condensed, sans-serif;"></v-btn>
                <v-btn class="mr-1 text-subtitle-1" :loading="loading" text="Save" variant="elevated" elevation="1" color="primary" @click="validateForm()" style="font-family: Saira Condensed, sans-serif;"></v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import axios from 'axios';
import { defineComponent } from 'vue'

export default defineComponent({
     emits: ["refresh"],
     data(){
        return {
            dialog: false,
            title: null,
            sub_title: null,
            action: null,
            
            snackbar: false,
            snackText: null,

            id: null,
            username: null,
            multi: null,
            betId: null,
            discordUsername: null,
            status: true,

            leaderboardId: null,
            loading: false,

            formRules: {
                required: value => !!value || 'Required',
                minValue: value => (value && value != 0) || 'Must be greater than 0',
                maxLength: value => (value && value.toString().length <= 10) || 'Must be less than or equal to 10 characters',
                noSpacesOnly: value => (value && value.trim().length > 0) || 'Username cannot be just spaces'
            },

            snackbar: false,
            snackText: null,
        }
     },
     methods: {
        initAdd(data){
            this.dialog = true
            this.title = 'Add User on Leaderboard'
            this.sub_title = 'Users on Ballyboy Leaderboard'
            this.action = 'add'

            this.leaderboardId = data._id
        },
        initUpdate(data){
            this.dialog = true
            this.title = 'Edit User on Leaderboard'
            this.sub_title = 'Users on Ballyboy Leaderboard'
            this.action = 'edit'

            this.id = data._id
            this.username = data.username
            this.multi = data.multi
            this.betId = data.betId
            this.discordUsername = data.discordUsername
            this.status = data.status
        },
        async validateForm(){
            const { valid } = await this.$refs.form.validate()

            if(valid){
                const token = localStorage.getItem('ballyboy-admin-accesstoken')
                this.loading = true
                let formData = {
                    username: this.username,
                    multi: this.multi,
                    betId: this.betId,
                    discordUsername: this.discordUsername,
                    leaderboardId: this.leaderboardId,
                }

                if(this.action == 'add'){
                    axios.post(process.env.VUE_APP_SERVER_URL + '/api/admin/leaderboard/user/add', formData,
                    { headers:
                        { 
                            'Authorization': `Bearer ${token}`,
                            'x-admin-key': process.env.VUE_APP_X_ADMIN_KEY,
                        }
                    }
                    ).then((res)=>{
                        if(res.data){
                            this.$emit('refresh')
                            this.$refs.form.reset()
                            this.dialog = false
                            this.loading = false
                        }
                    }).catch((error) => {
                        if (error.response) {
                            if (error.response.status === 401) {
                                    localStorage.removeItem("vuex");
                                    localStorage.removeItem("ballyboy-admin-accesstoken");
                                    window.location.reload();
                            } else {
                                console.log(`Error: ${error.response.status}`);
                            }
                        } 
                        else if (error.request) {
                            console.log('No response received', error.request);
                        } else {
                            console.log('Error', error.message);
                        }
                    });
                }
                else if(this.action == 'edit'){
                    axios.post(process.env.VUE_APP_SERVER_URL + '/api/admin/leaderboard/user/update/' + this.id, formData,
                    { headers:
                        { 
                            'Authorization': `Bearer ${token}`,
                            'x-admin-key': process.env.VUE_APP_X_ADMIN_KEY,
                        }
                    }
                    ).then((res)=>{
                        if(res.data){
                            this.$emit('refresh')
                            this.$refs.form.reset()
                            this.dialog = false
                            this.loading = false
                        }
                    }).catch((error) => {
                        if (error.response) {
                            if (error.response.status === 401) {
                                    localStorage.removeItem("vuex");
                                    localStorage.removeItem("ballyboy-admin-accesstoken");
                                    window.location.reload();
                            } else {
                                console.log(`Error: ${error.response.status}`);
                            }
                        } 
                        else if (error.request) {
                            console.log('No response received', error.request);
                        } else {
                            console.log('Error', error.message);
                        }
                    });
                }
            }
        },
        closeDialog(){
            this.$refs.form.reset()
            this.dialog = false
        }
     }
})
</script>

<style scoped>

</style>